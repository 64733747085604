import React from 'react';
import { Input, Label } from 'reactstrap';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { countryConfigs } from 'core/config/addresses';
import CountryCode from 'core/enums/CountryCode';
import { L10nContext, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import { ProfileFieldType } from 'core/ovations-api/enums';
import * as OvationsPortalApi from 'ovations-portal-api';

export interface ProfileAddressInfoCardProps {
    appSettings: OvationsPortalApi.Types.AppSettings;
    inputStatuses: { [name: string]: InputStatus };
    isEditProfile: boolean;
    l10n: L10nContext;
    onAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onStatusChange: (name: string) => (inputStatus: InputStatus) => void;
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    isProfileFieldEditable: (profileFieldType: OvationsApi.Enums.ProfileFieldType) => boolean;
    displayForReview: boolean;
    rewardPackageTypes?: OvationsApi.Enums.RewardPackageType[];
}

export default class ProfileAddressInfoCard extends React.Component<ProfileAddressInfoCardProps> {
    displayForReviewClassName = this.props.displayForReview ? 'input-readonly' : '';

    isFieldReadOnly(fieldType: ProfileFieldType): boolean {
        const { props } = this;
        if (this.props.isEditProfile) {
            return !props.isProfileFieldEditable(fieldType);
        }
        return false;
    }

    renderAddress1Input() {
        const { props } = this;

        return (
            <div className="col-md-4">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('address1')}
                    status={props.inputStatuses.address1}
                    id={elements.createNewProfile.id.address1}
                >
                    <Label for="address1">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_address1" />
                        </strong>
                    </Label>
                    <Input
                        value={props.profile.address.address1}
                        type="text"
                        id="address1"
                        name="address1"
                        onChange={props.onAddressChange}
                        disabled={this.isFieldReadOnly(ProfileFieldType.FullAddress)}
                        className={this.displayForReviewClassName}
                        required
                    />
                    <span className="invalid-feedback">
                        {!this.props.profile.address.address1 ? (
                            <LocalizedMessage id="errorMessage_requiredAddress1" />
                        ) : (
                            ''
                        )}
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    }

    renderAddress2Input() {
        const { props } = this;

        return (
            <div className="col-md-4">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('address2')}
                    status={props.inputStatuses.address2}
                    id={elements.createNewProfile.id.address2}
                >
                    <Label for="address2">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_address2" />
                        </strong>
                    </Label>
                    <Input
                        value={props.profile.address.address2 || ''}
                        id="address2"
                        name="address2"
                        onChange={props.onAddressChange}
                        disabled={this.isFieldReadOnly(ProfileFieldType.FullAddress)}
                        className={this.displayForReviewClassName}
                    />
                </ValidatedFormGroup>
            </div>
        );
    }

    render() {
        const { props } = this;
        const currentCountry = props.profile.address.country as CountryCode;
        const stateName =
            (currentCountry &&
                countryConfigs[currentCountry].subdivisions.find(
                    (division) => division.abbreviation === this.props.profile.address.state,
                )) ||
            null;

        return (
            <div className="mb-3">
                <div>
                    <hr />
                    <h5>
                        <LocalizedMessage id="profileEditCreate_addressInformation_title" />
                    </h5>
                    <div className="row">
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('country')}
                                status={props.inputStatuses.country}
                                id={elements.createNewProfile.id.country}
                            >
                                <Label for="state">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_country" />
                                    </strong>
                                </Label>

                                {!this.props.displayForReview && (
                                    <div>
                                        <Input
                                            type="select"
                                            id="country"
                                            name="country"
                                            value={this.props.profile.address.country}
                                            onChange={props.onAddressChange}
                                            disabled={this.isFieldReadOnly(ProfileFieldType.FullAddress)}
                                            required
                                        >
                                            <option value="">
                                                {props.l10n.localizeMessage('profileEditCreate_countryOption_default')}
                                            </option>
                                            {this.props.appSettings.enabledCountries.map((country) => (
                                                <option key={country.countryCode} value={country.countryCode}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </Input>
                                        <span className="invalid-feedback">
                                            <LocalizedMessage id="errorMessage_requiredCountry" />
                                        </span>
                                    </div>
                                )}
                                {this.props.displayForReview && <div>{this.props.profile.address.country}</div>}
                            </ValidatedFormGroup>
                        </div>
                        {this.renderAddress1Input()}
                        {this.renderAddress2Input()}
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('city')}
                                status={props.inputStatuses.city}
                                id={elements.createNewProfile.id.city}
                            >
                                <Label for="city">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_city" />
                                    </strong>
                                </Label>
                                <Input
                                    id="city"
                                    name="city"
                                    value={this.props.profile.address.city}
                                    onChange={props.onAddressChange}
                                    disabled={this.isFieldReadOnly(ProfileFieldType.FullAddress)}
                                    className={this.displayForReviewClassName}
                                    required
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_requiredCity" />
                                </span>
                            </ValidatedFormGroup>
                        </div>
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('state')}
                                status={props.inputStatuses.state}
                                id={elements.createNewProfile.id.state}
                            >
                                <Label for="state">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_state" />
                                    </strong>
                                </Label>

                                {!this.props.displayForReview && (
                                    <div>
                                        <Input
                                            className="tax-form"
                                            type="select"
                                            id="state"
                                            name="state"
                                            value={this.props.profile.address.state}
                                            onChange={props.onAddressChange}
                                            disabled={
                                                this.props.isEditProfile &&
                                                (!currentCountry ||
                                                    !props.isProfileFieldEditable(ProfileFieldType.FullAddress))
                                            }
                                            required
                                        >
                                            <option value="">
                                                {props.l10n.localizeMessage('profileEditCreate_stateOption_default')}
                                            </option>
                                            {currentCountry &&
                                                countryConfigs[currentCountry].subdivisions.map((subdiv) => (
                                                    <option key={subdiv.abbreviation} value={subdiv.abbreviation}>
                                                        {subdiv.name}
                                                    </option>
                                                ))}
                                        </Input>
                                        <span className="invalid-feedback">
                                            <LocalizedMessage id="errorMessage_requiredState" />
                                        </span>
                                    </div>
                                )}
                                {this.props.displayForReview && <div>{stateName && stateName.name}</div>}
                            </ValidatedFormGroup>
                        </div>
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('zipcode')}
                                status={props.inputStatuses.zipcode}
                                id={elements.createNewProfile.id.zipCode}
                            >
                                <Label for="zip">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_zip" />
                                    </strong>
                                </Label>
                                <Input
                                    id="zip"
                                    name="zip"
                                    value={this.props.profile.address.zip}
                                    pattern={currentCountry && countryConfigs[currentCountry].postalCode}
                                    onChange={props.onAddressChange}
                                    disabled={this.isFieldReadOnly(ProfileFieldType.FullAddress)}
                                    className={this.displayForReviewClassName}
                                    required
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_invalidZipCode" />
                                </span>
                            </ValidatedFormGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
