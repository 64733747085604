/* eslint-disable react/sort-comp */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { goBack, push } from 'connected-react-router';
import { Dictionary, find, isEqual, uniq } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Form, Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';

import { LocalizedMessage, L10nMessages, MessageValue, L10nConsumer } from 'core/l10n/components';

import AlternatePayeeForm from 'components/AlternatePayeeForm';
import LocalizedTitle from 'components/LocalizedTitle';
import elements from 'config/elements';
import * as routes from 'config/routes';

import coreElements from 'core/config/elements';
import * as OvationsApi from 'core/ovations-api';
import AvailableRewards from 'core/ovations-components/AvailableRewards';
import ESignDisclosure from 'core/ovations-components/ESignDisclosure';
import FAIcon from 'core/ovations-components/FAIcon';
import QuestionField from 'core/ovations-components/QuestionField';
import Terms from 'core/ovations-components/Terms';
import { focus } from 'core/util/dom';
import { toBool } from 'core/util/strings';
import CustomerRewardType from 'enums/CustomerRewardType';
import FormConfigType from 'enums/FormConfigType';
import {
    AddressCertificationStatus,
    ClaimStatus,
    RewardCalculationType,
    RewardPackageType,
} from 'core/ovations-api/enums';
import NotificationType from 'enums/NotificationType';
import withData from 'lib/withData';
import { claimLoader } from 'loaders/claimLoaders';
import { promotionLoader } from 'loaders/promotionLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';
import { CustomerClaim } from 'ovations-portal-api/definitions/CustomerRewardSummary';
import { Prompt } from 'react-router-dom';
import * as claim from 'redux-modules/claim';
import * as notification from 'redux-modules/notification';
import { ChoiceRewardCalculation, RewardPackage } from 'core/ovations-api/definitions';
import { accountLoader } from 'loaders/accountLoaders';
import { profileSettingsLoader } from 'loaders/profileSettingsLoader';
import * as account from 'redux-modules/account';
import * as layout from 'redux-modules/layout';
import ModalType from 'enums/ModalType';
import * as promotion from 'redux-modules/promotion';

import { hasTaxInformation, isTaxAddressPrimaryAddress } from 'redux-modules/customer';

import RewardCalculation from 'core/ovations-api/definitions/rewards/RewardCalculation';
import RewardOptionSelect from 'core/ovations-components/RewardOptionSelect';
import { ClaimFormSteps } from '../components/form-steps/claim-steps/ClaimFormSteps';
import { withProfileHandlers, WithProfileHandlerProps, ProfileFieldsOptions } from './profile/withProfileHandlers';

export interface ClaimContainerProps
    extends WithProfileHandlerProps<{
        claimNumber?: string;
        promotionNumber?: string;
        formConfigType?: FormConfigType;
    }> {}

interface ClaimContainerState {
    claim: OvationsPortalApi.Types.Claim;
    isSavingChanges: boolean;
    wasValidated: boolean;
    touchedReward: boolean;
    eSignDisclosureHasBeenReviewed: boolean;
    alternatePayeeSelected: boolean;
    selectedFormConfigType: FormConfigType;
    wasValidatedFormTypeConfig: boolean | null;
    submitAccount: boolean;
    addressWasUpdated: boolean;
    selectedRewardOptions: SelectedRewardOption[];
}
export const emptyAlternatePayee: OvationsApi.Types.AlternatePayee = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    zip: '',
    certificationState: {
        status: OvationsApi.Enums.AddressCertificationStatus.NonCertified,
    },
};

export interface SelectedRewardOption {
    [rewardCalculationId: string]: RewardPackage;
}

export class ClaimContainer extends React.Component<ClaimContainerProps, ClaimContainerState> {
    static getActivePromotion(props: ClaimContainerProps): OvationsPortalApi.Types.Promotion | undefined {
        const { promotionNumber } = props.match.params;
        if (promotionNumber) {
            return find(props.promotion.list, { number: +promotionNumber });
        }
        const claimBeingEdited = ClaimContainer.getClaimDetail(props);
        if (claimBeingEdited) {
            return claimBeingEdited.promotion;
        }
    }

    static getClaimDetail(props: ClaimContainerProps): OvationsPortalApi.Types.ClaimDetail | undefined {
        const { claimNumber } = props.match.params;
        if (claimNumber) {
            const claimsFromRewards = props.claim.results.filter(
                (claimValues) => claimValues.type === CustomerRewardType.Claim,
            ) as CustomerClaim[];

            return claimsFromRewards
                .map((claimRewardSummary) => claimRewardSummary.claim)
                .find((claimDetail) => claimDetail.number === Number(claimNumber));
        }

        return;
    }

    constructor(props: ClaimContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps: ClaimContainerProps) {
        const { claimNumber } = this.props.match.params;
        const nextClaimDetail = ClaimContainer.getClaimDetail(this.props);
        if (claimNumber && nextClaimDetail && ClaimContainer.getClaimDetail(prevProps) !== nextClaimDetail) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                claim: claim.selectors.toClaim(nextClaimDetail),
                eSignDisclosureHasBeenReviewed: nextClaimDetail.eSignAgreementAcceptance,
                selectedFormConfigType: nextClaimDetail.isForPrefund ? FormConfigType.Prefund : FormConfigType.Claim,
            });
        }
        if (this.props.account.me !== prevProps.account.me) {
            const profile = this.props.getProfile();
            this.props.updateProfile(profile);
        }
        if (
            !this.props.profileSettings.reviewInformationPage &&
            this.state.addressWasUpdated &&
            !this.state.submitAccount
        ) {
            this.submitClaim();
            this.setState({ submitAccount: true });
        }
        const isEditing = this.props.match.path === routes.EDIT_CLAIM;
        if (
            isEditing &&
            this.state.selectedRewardOptions.length < 1 &&
            this.state.claim.rewards &&
            this.state.claim.rewards.length > 0 &&
            !this.state.claim.rewards.some((x) => x.rewardPackage === null)
        ) {
            this.setSelectedRewardOptions();
        }
    }

    fetchCascadingDropdownOptions = (dataSetId: string, propertyId: number, filters: Dictionary<string>) => {
        return OvationsPortalApi.DataSet.fetchPropertyValues(dataSetId, propertyId, filters);
    };

    editSubmit = async () => {
        const editedClaim: OvationsPortalApi.Types.Claim = {
            ...this.state.claim,
            status: OvationsApi.Enums.ClaimStatus.PendingValidation,
        };
        await OvationsPortalApi.Claim.update(editedClaim);
    };

    createSubmit = async () => {
        const { selectedFormConfigType } = this.state;
        const createClaimRequest: OvationsPortalApi.Types.CreateClaimRequest = {
            answers: this.state.claim.answers,
            promotionNumber: Number(this.props.match.params.promotionNumber),
            rewards: this.state.claim.rewards,
            isForPrefund: selectedFormConfigType === FormConfigType.Prefund,
            alternatePayee: this.state.alternatePayeeSelected ? this.state.claim.alternatePayee : null,
        };
        const claimNumber = await OvationsPortalApi.Claim.create(createClaimRequest);
        return claimNumber;
    };

    validateForm = () => {
        const form = document.getElementById(elements.createClaim.id.form) as HTMLFormElement;
        let isValid = false;
        if (!form) {
            return false;
        }
        isValid = form.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true }, () => focus(form.querySelector('.form-control:invalid')));
        } else {
            this.setState({ wasValidated: false });
        }
        return isValid;
    };

    submitClaim = async () => {
        const isEditing = this.props.match.path === routes.EDIT_CLAIM;
        let claimNumber = Number(this.props.match.params.claimNumber);
        let navigationAction;
        this.setState({ isSavingChanges: true });
        if (isEditing) {
            await this.editSubmit();
            navigationAction = goBack();
        } else {
            claimNumber = await this.createSubmit();
            navigationAction = push(routes.CLAIMS);
        }
        this.props.dispatch(
            notification.actions.add({
                type: NotificationType.Success,
                message: 'notification_claimSubmitSuccess',
                values: { claimId: claimNumber },
                timeout: 3 * 1000,
            }),
        );
        this.props.dispatch(navigationAction);
        return;
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        try {
            if (
                !this.state.claim.rewards ||
                (this.state.claim.rewards?.length === 0 && this.state.claim.status === ClaimStatus.PendingValidation)
            ) {
                this.addRewardsToClaim();
            }

            if (!this.props.profileSettings.reviewInformationPage) {
                await this.submitAccount();
                return;
            }
            await this.submitClaim();
        } catch (err) {
            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Error,
                    message: 'notification_claimSubmitError',
                    timeout: 3 * 1000,
                }),
            );
            this.setState({
                isSavingChanges: false,
            });
        }
    };

    getOnAnswerChange = (promotionQuestionId: string) => {
        return (answer: OvationsApi.Types.PromotionQuestionAnswer) => {
            const newClaim = {
                ...this.state.claim,
                answers: {
                    ...this.state.claim.answers,
                    [promotionQuestionId]: answer,
                },
            };
            this.setState({ claim: newClaim });
        };
    };

    onESignDisclosureLinkClick = () => {
        this.setState({ eSignDisclosureHasBeenReviewed: true });
    };

    setClaimState = (claim: OvationsPortalApi.Types.Claim) => {
        this.setState({ claim, touchedReward: true });
    };

    onRewardChange = (parentChoiceCalcId: number, selectedRewardCalcId: number) => {
        const activePromotion = ClaimContainer.getActivePromotion(this.props);
        if (!activePromotion) {
            return;
        }

        OvationsApi.Claim.updateClaimReward(
            this.setClaimState,
            this.state.claim,
            activePromotion,
            parentChoiceCalcId,
            selectedRewardCalcId,
        );
    };

    getInitialState(): ClaimContainerState {
        const claimDetail = ClaimContainer.getClaimDetail(this.props) || claim.emptyClaimDetail;
        return {
            claim: claim.selectors.toClaim(claimDetail),
            isSavingChanges: false,
            wasValidated: false,
            touchedReward: false,
            eSignDisclosureHasBeenReviewed: claimDetail.eSignAgreementAcceptance,
            alternatePayeeSelected: false,
            selectedFormConfigType: claimDetail.isForPrefund ? FormConfigType.Prefund : FormConfigType.Claim,
            wasValidatedFormTypeConfig: false,
            submitAccount: false,
            addressWasUpdated: false,
            selectedRewardOptions: [],
        };
    }

    renderError() {
        return (
            <p>
                <LocalizedMessage id="claimDetail_errorMessage_promotionNotFound" />
            </p>
        );
    }

    renderPromotionQuestions = (questions: OvationsApi.Types.PortalPromotionQuestion[]) => {
        const promoQuestions = questions.map((promotionQuestion) => (
            <QuestionField
                key={promotionQuestion.id}
                claimStatus={this.state.claim.status}
                promotionQuestion={promotionQuestion}
                answer={this.state.claim.answers[promotionQuestion.id]}
                onChange={this.getOnAnswerChange(promotionQuestion.id)}
                fetchCascadingDropdownOptions={this.fetchCascadingDropdownOptions}
            />
        ));
        return promoQuestions;
    };

    renderESignDisclosure = (eSignAgreementUrl: string | undefined) => {
        if (!eSignAgreementUrl) {
            return;
        }
        const claimDetail = ClaimContainer.getClaimDetail(this.props) || claim.emptyClaimDetail;
        const inputProperties = {
            key: claimDetail.id,
            defaultChecked: claimDetail.eSignAgreementAcceptance,
            required: true,
        };

        return (
            <div>
                <div className="row">
                    <div className="col-md-6">{this.renderESignDisclosureHasNotBeenReviewed()}</div>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <h3 className="h5 m-0">
                        <LocalizedMessage id="claimDetail_heading_eSignDisclosure" />
                    </h3>
                </div>
                <ESignDisclosure
                    onLinkClick={this.onESignDisclosureLinkClick}
                    linkText={eSignAgreementUrl}
                    showAcceptDisclosure={this.state.eSignDisclosureHasBeenReviewed}
                    inputProps={inputProperties}
                />
            </div>
        );
    };

    renderTermsAndConditions = (termsAndConditionsHeading: string | undefined, termsAndConditions: string) => {
        const isEditing = this.props.match.path === routes.EDIT_CLAIM;
        const defaultChecked = isEditing && !this.state.touchedReward;
        const inputProperties = {
            key: defaultChecked, // triggers <input /> to re-render when defaultChecked changes
            defaultChecked,
            required: true,
        };

        if (!termsAndConditions) {
            return;
        }

        return (
            <div>
                <div className="d-flex align-items-center mb-3">
                    <h3 className="h5 m-0">
                        <LocalizedMessage id="claimDetail_termsAndConditions" />
                    </h3>
                </div>
                <Terms heading={termsAndConditionsHeading} body={termsAndConditions} inputProps={inputProperties} />
            </div>
        );
    };

    renderESignDisclosureHasNotBeenReviewed() {
        return !this.state.eSignDisclosureHasBeenReviewed && this.state.wasValidated ? (
            <div id={elements.createClaim.id.esignErrorBanner} className="d-flex align-items-center">
                <FAIcon icon={faExclamationTriangle} size="2x" className="me-3 mb-3 text-danger" />
                <div className="alert alert-danger text-danger fw-bold px-3 py-2 border-0 rounded-0" role="alert">
                    <LocalizedMessage id="claimDetail_errorMessage_eSignDisclosureHasNotBeenReviewed" />
                </div>
            </div>
        ) : null;
    }

    renderAvailableRewardsErrorBanner = (banner: JSX.Element) => (
        <div className="row">
            <div className="col-xs-12 col-md-6">{banner}</div>
        </div>
    );

    onAlternatePayeeRadioChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const alternatePayeeRadioSelected = !!toBool(e.currentTarget.value);
        this.setState({ alternatePayeeSelected: alternatePayeeRadioSelected });
        const { alternatePayee } = this.state.claim;
        if (!alternatePayeeRadioSelected) {
            const updatedClaim = {
                ...this.state.claim,
                alternatePayee: null,
            };
            return this.setState({ claim: updatedClaim });
        }

        if (!alternatePayee && alternatePayeeRadioSelected) {
            const updatedClaim = {
                ...this.state.claim,
                alternatePayee: { ...emptyAlternatePayee },
            };
            this.setState({ claim: updatedClaim });
        }
    };

    handleAddressFieldChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const { name: field, value } = event.currentTarget;
        const { alternatePayee } = this.state.claim;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        alternatePayee![field] = value;
        const updatedClaim = {
            ...this.state.claim,
            alternatePayee,
        };
        this.setState({ claim: updatedClaim });
    };

    renderAlternatePayeeForm = (hasAlternatePayee: boolean) => {
        if (!hasAlternatePayee) {
            return;
        }

        const activePromotion = ClaimContainer.getActivePromotion(this.props);

        // alt payee can not have multiple rewards
        // you must default to the first reward
        const rewardPackageType =
            activePromotion && activePromotion.rewardCalculations[0].alternatePayeeRewardPackageType
                ? activePromotion.rewardCalculations[0].alternatePayeeRewardPackageType
                : undefined;
        return (
            <div>
                <AlternatePayeeForm
                    alternatePayee={this.state.claim.alternatePayee}
                    enabledCountries={this.props.settings.enabledCountries}
                    readOnly={this.state.claim.status === OvationsApi.Enums.ClaimStatus.Validated}
                    handleAddressFieldChange={this.handleAddressFieldChange}
                    onAlternatePayeeRadioChange={this.onAlternatePayeeRadioChange}
                    alternatePayeeSelected={this.state.alternatePayeeSelected}
                    rewardPackageType={rewardPackageType}
                />
            </div>
        );
    };

    renderAvailibleRewards = () => {
        const promotion = ClaimContainer.getActivePromotion(this.props);

        const choiceRewardCalcs = promotion?.rewardCalculations.filter(
            (calc) => calc.type === RewardCalculationType.Choice,
        ) as ChoiceRewardCalculation[];

        return (
            <>
                {choiceRewardCalcs.map((calc, i) => (
                    <React.Fragment key={i}>
                        <AvailableRewards
                            rewardCalculation={calc}
                            onChange={(newSelection) => this.onRewardChange(calc.id, newSelection)}
                            wasValidated={this.state.wasValidated}
                            selectedReward={OvationsApi.Claim.getSelectedClaimReward(calc, this.state.claim.rewards)}
                            renderErrorBanner={this.renderAvailableRewardsErrorBanner}
                            columnWidths={{ xs: 12, lg: 4 }}
                            isRequired
                            showErrorBanner
                            choiceRewardNumber={i + 1}
                            shouldRenderCustomerNote
                        />
                        {i + 1 !== choiceRewardCalcs.length && <hr />}
                    </React.Fragment>
                ))}
            </>
        );
    };

    renderClaimForm(promotionFromRoute: OvationsPortalApi.Types.Promotion) {
        const { claimNumber } = this.props.match.params;
        const isEdit = !!claimNumber;
        const customerWillReceiveVirtualReward =
            promotionFromRoute.rewardCalculations.some((r) =>
                r.rewardPackages.some((rp) => rp.rewardPackageType === OvationsApi.Enums.RewardPackageType.VirtualUSA),
            ) ||
            (this.state.claim.rewards &&
                this.state.claim.rewards?.some((r) =>
                    r.rewardCalculation.rewardPackages.some(
                        (rp) => rp.rewardPackageType === OvationsApi.Enums.RewardPackageType.VirtualUSA,
                    ),
                ));

        return (
            <Form
                id={elements.createClaim.id.form}
                className={classNames({ 'was-validated': this.state.wasValidated })}
                noValidate
                onSubmit={this.onFormSubmit}
                onKeyDown={this.props.onKeyDown}
            >
                <Container className="mb-3">
                    <Row>
                        <Col>
                            <ClaimFormSteps
                                activePromotion={promotionFromRoute}
                                renderPromoQuestions={this.renderPromotionQuestions}
                                renderESignDisclosure={this.renderESignDisclosure}
                                renderAccountInformation={this.renderAccountInformation}
                                renderTermsAndConditions={this.renderTermsAndConditions}
                                renderAlternatePayeeForm={this.renderAlternatePayeeForm}
                                renderAvailibleRewards={this.renderAvailibleRewards}
                                renderRewardOptions={this.renderRewardOptionSelection}
                                isSavingChanges={this.state.isSavingChanges}
                                isEdit={isEdit}
                                claimNumber={claimNumber}
                                renderSelectClaimType={this.renderSelectClaimType}
                                validateForm={this.validateForm}
                                submitAccount={this.submitAccount}
                                selectedFormConfigType={this.state.selectedFormConfigType}
                                customerWillReceiveVirtualReward={customerWillReceiveVirtualReward}
                                addressWasUpdated={this.state.addressWasUpdated}
                                setAddressWasUpdated={this.setAddressUpdated}
                                reviewInformationPage={this.props.profileSettings.reviewInformationPage}
                                renderRewardsErrorBanner={this.renderAvailableRewardsErrorBanner}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
        );
    }

    // --------------------------------------------- SELECT CLAIM TYPE STUFF ----------------------------------------------------------------------

    onFormConfigTypeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.currentTarget;
        const selectedFormConfigType = FormConfigType[value];
        this.setState({ selectedFormConfigType });
    };

    renderClaimTypeError() {
        return <LocalizedMessage id="errorMessage_generic" />;
    }

    renderClaimTypeRadio() {
        const { selectedFormConfigType } = this.state;

        return (
            <div>
                <FormGroup tag="fieldset" className={`${selectedFormConfigType == null ? 'is-invalid' : ''}`}>
                    <legend className="h5 fw-bold">
                        <LocalizedMessage id="selectClaimType_title" />
                    </legend>
                    <FormGroup check>
                        <Label htmlFor={elements.selectClaimType.id.completedPurchasePicker} check>
                            <Input
                                name="formConfigType"
                                type="radio"
                                id={elements.selectClaimType.id.completedPurchasePicker}
                                value={FormConfigType.Claim}
                                checked={this.state.selectedFormConfigType === FormConfigType.Claim}
                                onChange={this.onFormConfigTypeChange}
                                required
                            />
                            <span className="fw-bold">
                                {' '}
                                <LocalizedMessage id="selecteClaimType_label_completedPurchase" />
                            </span>
                            <LocalizedMessage id="selecteClaimType_label_completedPurchase_description" />
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label htmlFor={elements.selectClaimType.id.plannedPurchasePicker} check>
                            <Input
                                name="formConfigType"
                                type="radio"
                                id={elements.selectClaimType.id.plannedPurchasePicker}
                                value={FormConfigType.Prefund}
                                checked={this.state.selectedFormConfigType === FormConfigType.Prefund}
                                onChange={this.onFormConfigTypeChange}
                                required
                            />
                            <span className="fw-bold">
                                {' '}
                                <LocalizedMessage id="selecteClaimType_label_plannedPurchase" />
                            </span>
                            <LocalizedMessage id="selecteClaimType_label_plannedPurchase_description" />
                        </Label>
                    </FormGroup>
                    <span className="invalid-feedback">Purchase type selection is required</span>
                </FormGroup>
            </div>
        );
    }

    renderSelectClaimType = () => {
        const activePromotion = ClaimContainer.getActivePromotion(this.props);

        return (
            <div className="py-4">{activePromotion ? this.renderClaimTypeRadio() : this.renderClaimTypeError()}</div>
        );
    };
    // --------------------------------------------- END CLAIM TYPE STUFF ------------------------------------------------------------------------

    // -------------------------------------------- ACCOUNT EDIT AND REVIEW STUFF ----------------------------------------------------------------

    doesInitialUserStateHaveTaxData = false;

    componentDidMount() {
        const doesUserHaveTaxAddress = hasTaxInformation(
            this.props.account.me,
            this.props.profileSettings.disableSecurityNumberCollection,
        );
        this.doesInitialUserStateHaveTaxData = doesUserHaveTaxAddress;

        const doesTaxAddressMatchPrimary = isTaxAddressPrimaryAddress(this.props.account.me);
        this.props.updateMailingAsTaxAddress(doesTaxAddressMatchPrimary);
        this.props.updateProfile(this.props.getProfile());

        const isEditing = this.props.match.path === routes.EDIT_CLAIM;
        if (!isEditing) {
            this.addRewardsToClaim();
        }
    }

    submitAccount = async () => {
        const { props } = this;
        const isPhoneNumberValid = this.props.validatePhoneNumber(props.profile.phoneNumber);

        if (!isPhoneNumberValid) {
            this.setState({ wasValidated: true });
            // focus(e.currentTarget.querySelector('#phone'));
            return;
        }

        if (
            (props.account.me && !isEqual(props.account.me.address, props.profile.address)) ||
            props.account.me?.address.status === AddressCertificationStatus.NonCertified
        ) {
            this.props.openAddressValidationModal();
            return;
        }
        await this.saveAccountChanges();
        return;
    };

    setAddressUpdated = (updated: boolean) => {
        this.setState({ addressWasUpdated: updated });
    };

    saveAccountChanges = async () => {
        try {
            const profile = this.props.transformRegistrationToProfile(this.props.profile);
            this.setState({ isSavingChanges: true });
            await this.props.dispatch(account.actions.updateProfile(profile));
            this.setAddressUpdated(true);
        } catch (e) {
            if (e.response && e.response.status === 409) {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileInformationExists',
                    }),
                );
            } else {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileUpdateFailure',
                    }),
                );
            }
        }
        if (this.props.profileSettings.reviewInformationPage) {
            this.setState({ isSavingChanges: false });
        }
    };

    toggleChangePassword = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.PasswordChange));
    };

    renderAccountInformation = (isForReview: boolean) => {
        const activePromotion = ClaimContainer.getActivePromotion(this.props);

        const rewardPackageTypes: RewardPackageType[] = [];

        activePromotion?.rewardCalculations
            .filter((x) => x.rewardPackages !== null)
            .forEach((x) =>
                x.rewardPackages.map((rp) => rewardPackageTypes.push(rp.rewardPackageType as RewardPackageType)),
            );

        this.state.claim.rewards
            ?.filter((x) => x.rewardCalculation.rewardPackages !== null)
            .forEach((x) =>
                x.rewardCalculation.rewardPackages.map((rp) =>
                    rewardPackageTypes.push(rp.rewardPackageType as RewardPackageType),
                ),
            );

        const options: Partial<ProfileFieldsOptions> = {
            isEditProfile: true,
            toggleChangePassword: this.toggleChangePassword,
            registrationErrors: {},
            userHasTaxData: this.doesInitialUserStateHaveTaxData,
            directPhoneNumberError: this.props.phoneNumberError,
            isRequireSSNForGalileo: promotion.selectors.isRequireSSNForGalileo(this.props.promotion.list),
            isRequireDOBForGalileo: promotion.selectors.isRequireDOBForGalileo(this.props.promotion.list),
            fieldControlOption: this.props.profileSettings.fieldControlOption,
            isForReview,
            rewardPackageTypes: uniq(rewardPackageTypes),
            isTaxable: (activePromotion && activePromotion.isTaxable) || false,
            disableSSN: this.props.profileSettings.disableSecurityNumberCollection,
        };
        return (
            <div className="container py-4">
                <L10nConsumer>
                    {(l10n) => (
                        <Prompt
                            when={this.props.hasChanges()}
                            message={l10n.localizeMessage('generic_unsavedChanges')}
                        />
                    )}
                </L10nConsumer>
                <div>{this.props.renderProfileFields(this.saveAccountChanges, options, true)}</div>
            </div>
        );
    };
    // -------------------------------------------------------------------------------------------------------------------------------------------

    getTitleDescriptors(): { id: keyof L10nMessages; values?: Dictionary<MessageValue> } {
        const { claimNumber } = this.props.match.params;
        return claimNumber ? { id: 'pageTitle_claim_edit', values: { claimNumber } } : { id: 'pageTitle_claim_submit' };
    }

    changeRewardOption = (rewardCalculation: RewardCalculation, rewardPackage: RewardPackage) => {
        const index = this.state.selectedRewardOptions?.findIndex((x) => x[rewardCalculation.id]);
        const selectedRewardOptions = [...(this.state.selectedRewardOptions || [])];

        if (index !== undefined && index !== -1) {
            if (selectedRewardOptions) {
                selectedRewardOptions[index][rewardCalculation.id] = rewardPackage;
                this.setState({ selectedRewardOptions });
            }
        } else {
            selectedRewardOptions.push({ [rewardCalculation.id]: rewardPackage });
            this.setState({ selectedRewardOptions });
        }

        const claim = { ...this.state.claim };
        const rewardIndex = claim.rewards?.findIndex((x) => x.rewardCalculation.id === rewardCalculation.id);

        if (rewardIndex !== undefined && rewardIndex !== -1) {
            if (claim.rewards) {
                claim.rewards[rewardIndex].rewardPackage = rewardPackage;
                this.setState({ claim });
            }
        } else {
            // new reward in a reward option scenario
            const reward: OvationsApi.Types.Reward = {
                rewardCalculation,
                claimId: claim.id,
                cardRewardDetail: null,
                status: OvationsApi.Enums.RewardStatus.Inactive,
                validatedAmount: 0,
                id: null,
                rewardStatusHistory: [],
                rewardPackage,
            };
            if (!claim.rewards) {
                claim.rewards = [];
            }
            claim.rewards?.push(reward);
            this.setState({ claim });
        }
    };

    addRewardsToClaim = () => {
        const promotion = ClaimContainer.getActivePromotion(this.props);
        const claim = { ...this.state.claim };
        if (!promotion) {
            return claim;
        }
        if (promotion.rewardCalculations) {
            claim.rewards = [];
            promotion.rewardCalculations?.forEach((rewardCalculation) => {
                if (rewardCalculation.type !== RewardCalculationType.Choice) {
                    const reward: OvationsApi.Types.Reward = {
                        rewardCalculation,
                        claimId: claim.id,
                        cardRewardDetail: null,
                        status: OvationsApi.Enums.RewardStatus.Inactive,
                        validatedAmount: 0,
                        id: null,
                        rewardStatusHistory: [],
                        rewardPackage:
                            rewardCalculation.rewardPackages.length > 1 ? null : rewardCalculation.rewardPackages[0],
                    };
                    claim.rewards?.push(reward);
                }
            });
        }
        this.setState({ claim });
    };

    setSelectedRewardOptions() {
        // IF REWARD ALREADY HAS REWARD PACKAGE SELECTION, MAKE LIST REFLECT IT

        const selectedRewardOptions: SelectedRewardOption[] = [];

        if (this.state.claim.rewards) {
            this.state.claim.rewards.forEach((reward) => {
                if (reward.rewardPackage) {
                    const selectedRewardOption: SelectedRewardOption = {};
                    const rewardPackage = { ...reward.rewardPackage };
                    selectedRewardOption[reward.rewardCalculation.id] = rewardPackage;
                    selectedRewardOptions.push(selectedRewardOption);
                }
            });

            if (this.state.selectedRewardOptions && this.state.selectedRewardOptions?.length < 1) {
                this.setState({ selectedRewardOptions });
            }
        }
    }

    rewardOptionIsChecked = (rewardCalculation: RewardCalculation, rewardPackage: RewardPackage) => {
        const selectedRewardOption = this.state.selectedRewardOptions?.find((x) => x[rewardCalculation.id]);

        if (!selectedRewardOption) {
            return false;
        }

        if (selectedRewardOption[rewardCalculation.id].id === rewardPackage.id) {
            return true;
        }
        return false;
    };

    renderRewardOptionSelectionErrorBanner = () => {
        const hasNullRewardPackage = this.state.claim.rewards?.find((x) => x.rewardPackage === null);

        return hasNullRewardPackage && this.state.wasValidated ? (
            <div id={coreElements.availableRewards.id.rewardErrorBanner} className="d-flex align-items-center">
                <FAIcon icon={faExclamationTriangle} size="2x" className="me-3 mb-3 text-danger" />
                <div className="alert alert-danger text-danger fw-bold px-3 py-2 border-0 rounded-0" role="alert">
                    <LocalizedMessage id="claimDetail_errorMessage_rewardNotSelected" />
                </div>
            </div>
        ) : null;
    };

    renderRewardOptionSelection = () => {
        const promotion = ClaimContainer.getActivePromotion(this.props);
        const validRewardCalcs = promotion
            ? promotion.rewardCalculations.filter((x) => x.rewardPackages.length > 1)
            : [];
        return (
            <RewardOptionSelect
                rewardCalculations={validRewardCalcs}
                renderRewardOptionSelectionErrorBanner={this.renderRewardOptionSelectionErrorBanner}
                rewardOptionIsChecked={this.rewardOptionIsChecked}
                changeRewardOption={this.changeRewardOption}
            />
        );
    };

    render() {
        const activePromotion = ClaimContainer.getActivePromotion(this.props);
        const title = this.getTitleDescriptors();
        return (
            <div className="container py-4">
                <LocalizedTitle id={title.id} values={title.values} />
                <h1 className="page__title">
                    <LocalizedMessage id={title.id} values={title.values} />
                </h1>
                {!activePromotion ? this.renderError() : this.renderClaimForm(activePromotion)}
            </div>
        );
    }
}

const ClaimContainerWithData = withData<ClaimContainerProps>(
    claimLoader,
    promotionLoader,
    accountLoader,
    profileSettingsLoader,
)(withProfileHandlers(ClaimContainer));
export default connect(/* istanbul ignore next */ (state) => state)(ClaimContainerWithData);
