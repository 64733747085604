import * as promotionQuestion from 'core/ovations-api/__mocks__';
import { ChoiceRewardCalculation } from 'core/ovations-api/definitions';
import RewardCalculationType from 'core/ovations-api/enums/RewardCalculationType';
import * as OvationsPortalApi from 'ovations-portal-api';

export const mockChoiceRewardCalculation: ChoiceRewardCalculation = {
    id: 1,
    type: RewardCalculationType.Choice,
    label: null,
    alternatePayeeRewardPackageId: null,
    alternatePayeeRewardPackageType: null,
    rewardPackageName: 'Choice package name',
    rewardPackages: [],
    choices: [
        {
            id: 2,
            type: RewardCalculationType.Static,
            label: 'Label1',
            amount: 10,
            alternatePayeeRewardPackageId: null,
            alternatePayeeRewardPackageType: null,
            rewardPackageName: 'Single load package name',
            rewardPackages: [],
        },
        {
            id: 3,
            type: RewardCalculationType.Static,
            label: 'Label2',
            amount: 20,
            alternatePayeeRewardPackageId: null,
            alternatePayeeRewardPackageType: null,
            rewardPackageName: 'Reloadable package name',
            rewardPackages: [],
        },
    ],
};

export const mockPortalPromotion: OvationsPortalApi.Types.Promotion = {
    id: 'portal-promotion-1',
    number: 1,
    name: 'Mock Portal Promotion',
    termsAndConditions: 'Terms text',
    termsAndConditionsHeading: 'Terms Heading',
    eSignAgreementUrl: 'eSign agreement url',
    questions: [
        promotionQuestion.mockPortalPromotionQuestion,
        promotionQuestion.mockNumberPortalPromotionQuestion,
        promotionQuestion.mockDropdownPortalPromotionQuestion,
        promotionQuestion.mockCascadingDropdownPortalPromotionQuestion,
        promotionQuestion.mockDatetimePortalPromotionQuestion,
        promotionQuestion.mockFileUploadPortalPromotionQuestion,
    ],
    rewardCalculations: [mockChoiceRewardCalculation],
    rewardPrefundEnabled: false,
    hasAlternatePayee: false,
    isTaxable: false,
    limitReached: false,
};
